@tailwind base;
@tailwind components;
@tailwind utilities;
@layer {
  @font-face {
    font-family: "Inter";
    src: url("../fonts/Inter/Inter.ttf");
  }
  @font-face {
    font-family: "Oswaldbold";
    src: url("../fonts/Oswald/Oswald-Bold.ttf");
  }
  @font-face {
    font-family: "OswalExtraligt";
    src: url("../fonts/Oswald/Oswald-ExtraLight.ttf");
  }
  @font-face {
    font-family: "OswaldLight";
    src: url("../fonts/Oswald/Oswald-Light.ttf");
  }
  @font-face {
    font-family: "OswaldMedium";
    src: url("../fonts/Oswald/Oswald-Medium.ttf");
  }
  @font-face {
    font-family: "OswaldReguler";
    src: url("../fonts/Oswald/Oswald-Regular.ttf");
  }
  @font-face {
    font-family: "OswaldSemibold";
    src: url("../fonts/Oswald/Oswald-SemiBold.ttf");
  }
  :root {
    --color-primary: 181 1 2;
    --color-text: 33 33 33;
    --color-text-white: 255 255 255;
    --color-success: 0 200 81;
    --color-info: 51 181 229;
    --color-warn: 255 187 51;
    --color-error: 254 78 78;
  }

  :root[class~="dark"] {
    --color-primary: 247 147 34;
    --color-text: 255 255 255;
    --color-text-white: 255 255 255;
    --color-success: 0 200 81;
    --color-info: 51 181 229;
    --color-warn: 255 187 51;
    --color-error: 254 78 78;
  }
  body {
  min-height: 100vh;
  margin: 0;
  padding: 0;
  overflow-x: hidden !important;
  background-image: url('../img/bg.jpeg');
  background-repeat: repeat;
  }
}
.container{
  @apply lg:!px-[120px] px-2
}
.navbarline{
  @apply w-[30px] h-[2px] my-2 block bg-black
}
.navbarline-active > span:nth-child(1){
  @apply rotate-45
}
.navbarline-active > span:nth-child(2){
  @apply scale-0
}
.navbarline-active > span:nth-child(3){
  @apply -rotate-45
}
.navbar-fixed{
  @apply fixed z-[999] bg-opacity-80 backdrop-blur-sm
}
.navbar-item{
  @apply text-lg rounded uppercase lg:group-hover:border-b-2 lg:text-white text-slate-900 py-2 lg:py-0 mx-8 lg:group-hover:text-white group-hover:text-red-600 flex h-6
}
